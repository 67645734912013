.historial-container {
  padding-top: 30px;
  text-align: center;

  .historial {
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 80%;
    padding: 30px;
    margin: auto;

    .busqueda-rut {
      text-align: left;
      padding: 15px 0px;
      overflow: auto;

      input {
        border: solid #ddd 1px;
        border-radius: 5px;
        height: 35px;
        padding: 10px;
        width: 250px;
        background: white;
        color: #333;
        font-weight: 100;

        &:focus {
          outline: none;
        }
      }
    }

    .actualizar {
      width: 60px;
      height: 60px;
      padding-top: 18px;
      margin: auto;
      border-radius: 40px;
      margin-bottom: 13px;
      cursor: pointer;
      position: absolute;
      top: 78px;
      background: #ffffff;
      right: calc(10% - 15px);
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);

      &:hover {
        background: #f7f7f7;
      }

      i {
        font-size: 25px;
      }
    }
    .header-table {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      p {
        text-align: left;
        font-size: 12px;
        margin: 0;
        margin-bottom: 1px;
        font-style: italic;
        color: #555;
        width: 100%;
      }
    }
    .btn-page {
      display: inline-block;
      border: none;
      margin: 5px 1px 1px 1px;
    }
    .clickable-row {
      cursor: pointer;
    }
    table {
      text-align: left;

      thead {
        ul.filter-dropdown {
          list-style: none;
          padding: 0;
          margin: 0;
          font-weight: normal;
          font-size: 12px;
          position: absolute;
          display: none;

          li {
            a {
              padding: 10px;
              background: #f7e7e7;
              min-width: 140px;
              border-bottom: 2px solid white;
              display: block;
              cursor: pointer;

              &.seleccionado {
                background-color: #d4edda;
                color: #155724;
              }

              &:hover {
                border-bottom: 2px solid #ccc;
              }
            }
          }
        }

        th.with-dropdown {
          cursor: default;
        }

        th.with-dropdown:hover {
          ul.filter-dropdown {
            display: block;
          }
        }
      }

      tbody {
        tr {
          font-size: 14px;
          &:hover {
            background: #f7f7f7;
          }

          td {
            min-width: 30px;
            max-width: 250px;
          }
        }
      }
    }
  }
}
