.ingreso {
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;

  form {
    width: 100%;
    max-width: 330px;
    padding: 30px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &.ocultar {
      display: none;
    }

    .form-group {
      .form-control {
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding: 10px;
        font-size: 16px;
        border-radius: 0;
        border-bottom: 0;

        &:focus {
          z-index: 2;
        }

        &:first-child {
          border-top-right-radius: 0.3rem;
          border-top-left-radius: 0.3rem;
        }

        &:last-child {
          border-bottom-right-radius: 0.3rem;
          border-bottom-left-radius: 0.3rem;
          border-bottom: 1px solid #ced4da;
          margin-bottom: 5px;
        }
      }

      select {
        &.form-control {
          height: 46px;
          border-radius: 0;
          -webkit-appearance: none;
        }

        &.ng-invalid {
          color: #636c72;
        }
      }
    }
  }

  .alert {
    margin: 15px 0px;
    font-size: small;

    &.alert-warning {
      margin-top: 0;
    }
  }

  small {
    margin-top: 10px;
    display: block;
    line-height: 1.2;
    color: darkgray;
  }
}
