// variables del template global
@import "styles/variables";

// bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";

// animaciones
@import "styles/animaciones";

// estilos globales
@import "styles/globales";

// componentes
@import "app/app.component.scss";
@import "app/search/search.component.scss";
@import "app/error/error.component.scss";
@import "app/history/history.component.scss";
@import "app/login/login.component.scss";
@import "app/result/result.component.scss";
@import "app/components/button/button.component.scss";
@import "app/components/header/header.component.scss";

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
