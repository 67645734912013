.navbar {
  background: #fff;
  border-bottom: 1px solid #dee2e6;
  padding: 5px 15px;

  .navbar-brand {
    padding: 0;

    img {
      height: 52px;
    }
  }

  a {
    cursor: pointer;
  }

  .nav-item {
    .nav-link {
      font-weight: 100;
      color: #05164c;

      &.activo,
      &:hover {
        color: #000000;
        text-decoration: underline;
        background: #f7f7f7;
      }
    }
  }

  .alert-container {
    bottom: -6rem;
    display: flex;
    margin-left: -15px;
    position: absolute;
    align-items: center;
    justify-content: center;
    right: 0;
    width: 100%;

    > div {
      width: 80%;
    }
  }
}
