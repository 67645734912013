.header-container {
  position: sticky;
  top: 0;
}

.button-container {
  background-color: #fff;
  bottom: 0;
  padding: 30px 0;
  position: sticky;
}

.resultado-estudio {
  text-align: center;
  height: $alto-ontenedor;
  display: flex;

  .contenedor-resultado {
    width: 80%;
    max-width: 700px;
    background: #ffffff;
    margin: auto;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    padding: 50px;

    &.large {
      max-width: 950px;
    }

    .cargando-inicio {
      font-size: 90px;
      color: #ccc;
    }

    h1 {
      border-bottom: 1px solid #eee;
      padding-bottom: 5px;
      margin-bottom: 25px;
    }

    h3 {
      text-align: left;
      font-size: 1rem;
    }

    .separador {
      width: 100%;
      height: 1px;
      border-bottom: solid 1px #f7f7f7;
      margin: 25px 0px;
    }

    ul.pre-administradores {
      list-style: none;
      padding: 15px;
      background: #f5f5f5;

      li {
        text-transform: uppercase;
        text-align: left;

        i {
          color: #555;
        }
      }
    }

    table.info-base-sociedad {
      tr {
        border: none;
        text-align: left;

        th {
          font-weight: normal;
        }

        td {
          padding-left: 15px;
          text-transform: uppercase;
          font-weight: 100;
        }
      }
    }

    ul.apoderados {
      list-style: none;
      padding: 0;

      li {
        text-transform: uppercase;
        text-align: left;
        margin-bottom: 10px;
        font-weight: 100;
      }
    }

    table.poderes {
      width: 100%;
      margin-top: 15px;
      font-size: 0.7rem;
      border-radius: 5px;
      border: 1px solid #555;
      border-collapse: collapse;
      table-layout: fixed;

      td,
      th {
        padding: 10px;
        text-align: left;
        font-weight: normal;
        border: 1px solid #555;
      }

      .producto {
        width: 15%;
      }
      .apoderado {
        width: 50%;
      }
      .actuar {
        width: 35%;
      }

      .product-not-approval-message {
        font-size: 1rem;
        font-weight: 400;
      }

      th {
        font-size: 1rem;
      }

      tbody {
        td {
          font-weight: 150;

          ul.poderes-apoderados {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              text-transform: uppercase;
              text-align: left;
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    table.actuations {
      width: 100%;
      margin-top: 15px;
      font-size: 0.9rem;
      border-radius: 5px;
      border: 1px solid #555;
      border-collapse: collapse;
      table-layout: fixed;

      td,
      th {
        padding: 5px;
        text-align: center;
        font-weight: bold;
        border: 1px solid #555;
      }

      th {
        font-size: 1rem;
      }

      tbody {
        td {
          font-weight: 170;
        }
      }
    }

    .rut {
      white-space: nowrap;
    }

    p {
      b {
        color: #000;
      }
    }

    .estado {
      text-align: left;
      line-height: 1.3;
      font-size: 12px;
    }

    .inform_footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    button,
    .alert {
      margin-top: 15px;

      i {
        font-style: normal;
      }
    }

    .footnote {
      display: block;
      color: red;
      font-size: smaller;
      margin-top: 0.5rem;
    }
  }
}

@media print {
  .header-container {
    display: none;
  }

  .resultado-estudio {
    display: block;
    margin-top: 30px;

    .contenedor-resultado {
      width: 100%;
      padding: 30px;

      p {
        color: #000000;
      }

      button,
      .alert {
        display: none;
      }
    }
  }
}
