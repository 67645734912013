.busqueda-principal {
  height: $alto-ontenedor;
  display: flex;

  .busqueda-container {
    background: #ffffff;
    margin: auto;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    padding: 50px;

    p {
      font-size: 14px;
      text-align: left;
      color: #555;
      font-weight: 100;
    }

    form {
      .form-group {
        .form-control {
          position: relative;
          box-sizing: border-box;
          height: auto;
          padding: 10px;
          font-size: 16px;
        }
      }
    }

    .alert {
      margin: 15px 0px;
      font-size: small;
    }
  }

  .checkbox-group {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .checkbox-group :nth-child(3n) {
    text-align: right;

    .checkbox-label {
      margin-right: 0;
      display: inline;
    }
  }

  .risk-checkbox-container {
    text-align: center;
  }

  .risk-checkbox {
    display: inline-block;
  }

  .checkbox-label {
    font-size: 14px;
    text-align: left;
    color: #555;
    font-weight: 100;
    display: inline;
  }
}
